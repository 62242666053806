import { designs } from '@gelatoas/design-editor-calendar';

import { LayoutGeneratedTextType, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import { TextAlign } from 'editor/src/store/fonts/types';
import { MockupRole } from 'editor/src/store/mockup/types';

import numberToOffset from 'editor/src/util/layouts/numberToOffset';
import numberToPadding from 'editor/src/util/layouts/numberToPadding';
import defineLayoutSchema from 'editor/src/util/test/defineLayoutSchema';

const INTERNAL_PADDING = 8;
const EXTERNAL_PADDING = 12;

export const LAYOUT_TAG_GRID = 'grid';
export const LAYOUT_TAG_WALLPAPERS = 'wallpapers';
export const LAYOUT_TAG_MOCKUP = 'mockup';

const schemas: LayoutSchema[] = [
  defineLayoutSchema('1', 1, 1, 10, 0, 0, 0, [[{ width: 1, height: 1, type: 'image' }]], true),
  defineLayoutSchema(
    'i1t1_4',
    1,
    6,
    10,
    0,
    0,
    0,
    [[{ width: 1, height: 6, type: 'image' }], [], [], [], [], [{ width: 1, height: 1, type: 'text' }]],
    true,
  ),
  defineLayoutSchema(
    'i1t1_4b',
    1,
    6,
    10,
    0,
    0,
    0,
    [[{ width: 1, height: 6 }], [{ width: 1, height: 1, type: 'text' }], [], [], [], []],
    true,
  ),
  defineLayoutSchema('2', 1, 1, 10, 0, EXTERNAL_PADDING, 0, [[{ width: 1, height: 1 }]]),
  defineLayoutSchema('i1t1_5', 1, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [{ width: 1, height: 6 }],
    [],
    [],
    [],
    [],
    [{ width: 1, height: 1, type: 'text' }],
  ]),
  defineLayoutSchema('i1t1_5b', 1, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [{ width: 1, height: 6 }],
    [{ width: 1, height: 1, type: 'text' }],
    [],
    [],
    [],
    [],
  ]),
  defineLayoutSchema('i1t1_1', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 3, height: 5 },
      { width: 1, height: 2, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i1t1_2', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 2, type: 'text' },
      { width: 3, height: 5 },
    ],
  ]),
  defineLayoutSchema('i1t1_3', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [{ width: 2, height: 6 }],
    [],
    [],
    [],
    [],
    [],
    [{ width: 2, height: 1, type: 'text' }],
  ]),
  defineLayoutSchema('3', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      {
        width: 1,
        height: 2,
      },
      {
        width: 1,
        height: 2,
      },
    ],
  ]),
  defineLayoutSchema('i2t2_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 6 },
      { width: 1, height: 6 },
    ],
    [],
    [],
    [],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('4', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 2 },
      {
        width: 1,
        height: 1,
      },
    ],
    [
      {},
      {
        width: 1,
        height: 1,
      },
    ],
  ]),
  defineLayoutSchema('5', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [{ width: 1, height: 1 }],
  ]),
  defineLayoutSchema('i3t1_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [{ width: 2, height: 3 }],
    [],
    [],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [{ width: 2, height: 1, type: 'text' }],
  ]),
  defineLayoutSchema('i3t2_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 6 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [{}, { width: 1, height: 3 }],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i3t2_2', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 3 },
      { width: 1, height: 6 },
    ],
    [],
    [],
    [{ width: 1, height: 3 }],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('6', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i4t1_1', 2, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [{ width: 2, height: 1, type: 'text' }],
  ]),
  defineLayoutSchema('i4t0_1', 4, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i4t2_2', 4, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 8 },
      { width: 1, height: 8 },
      { width: 1, height: 8 },
      { width: 1, height: 8 },
    ],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i4t2_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('7', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [{}, { width: 1, height: 1 }],
  ]),
  defineLayoutSchema('8', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [{ width: 1, height: 1 }, {}, { width: 1, height: 1 }],
  ]),
  defineLayoutSchema('9', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('10', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 2, height: 3 },
      { width: 1, height: 2 },
      { width: 1, height: 2 },
    ],
    [],
    [{}, {}, { width: 2, height: 3 }],
    [
      { width: 1, height: 2 },
      { width: 1, height: 2 },
    ],
  ]),
  defineLayoutSchema('i6t0_1', 4, 8, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 8 },
      { width: 1, height: 4 },
      { width: 1, height: 8 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [{}, { width: 1, height: 4 }, {}, { width: 1, height: 4 }],
  ]),
  defineLayoutSchema('i6t2_1', 4, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 8 },
      { width: 1, height: 4 },
      { width: 1, height: 8 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [{}, { width: 1, height: 4 }, {}, { width: 1, height: 4 }],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('11', 5, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 3, height: 3 },
      { width: 1, height: 1 },
    ],
    [{ width: 1, height: 1 }, {}, {}, {}, { width: 1, height: 1 }],
    [{ width: 1, height: 1 }, {}, {}, {}, { width: 1, height: 1 }],
  ]),
  defineLayoutSchema('12', 4, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [{}, { width: 1, height: 2 }, {}, { width: 1, height: 2 }],
    [{ width: 1, height: 1 }, {}, { width: 1, height: 1 }],
  ]),
  defineLayoutSchema('i8t0_1', 4, 8, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
  ]),
  defineLayoutSchema('i8t2_1', 4, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i9t1_1', 3, 10, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [{ width: 3, height: 1, type: 'text' }],
  ]),
  defineLayoutSchema('13', 6, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [{}, {}, { width: 2, height: 2 }],
    [{ width: 1, height: 1 }, { width: 1, height: 1 }, {}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
  ]),
  defineLayoutSchema('14', 4, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('15', 6, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [{}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [{ width: 1, height: 1 }, { width: 1, height: 1 }, {}, {}, { width: 1, height: 1 }, { width: 1, height: 1 }],
  ]),
  defineLayoutSchema('16', 6, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  {
    ...defineLayoutSchema('wallpaper-sample-layout', 5, 20, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, [
      [
        {
          width: 2,
          height: 1,
          type: 'text',
          textI18nKey: 'Full image',
          static: true,
          fontStyles: {
            color: '#989898',
            textAlign: TextAlign.left,
          },
          id: 'full-wall-label',
        },
        {
          width: 3,
          height: 1,
          type: 'text',
          textI18nKey: 'Full scale fragment',
          static: true,
          fontStyles: {
            color: '#989898',
            textAlign: TextAlign.left,
          },
          id: 'sample-label',
        },
      ],
      [
        {
          type: 'image',
          width: 2,
          height: 9,
          linkId: '0',
          id: 'full-wall',
          saveSourceFrame: true,
        },
        {
          type: 'image',
          width: 3,
          height: 19,
          linkId: '0',
          id: 'sample',
        },
      ],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [
        {
          width: 1,
          height: 1,
          type: 'text',
          textI18nKey: 'Company name',
          static: true,
          fontStyles: {
            color: '#989898',
            textAlign: TextAlign.left,
          },
        },
      ],
      [
        {
          width: 1,
          height: 2,
          type: 'text',
          textI18nKey: 'Type company name',
          fontStyles: {
            textAlign: TextAlign.left,
          },
        },
      ],
      [],
      [
        {
          width: 1,
          height: 1,
          type: 'text',
          textI18nKey: 'Company website',
          static: true,
          fontStyles: {
            color: '#989898',
            textAlign: TextAlign.left,
          },
        },
      ],
      [
        {
          width: 1,
          height: 2,
          type: 'text',
          textI18nKey: 'Type company website',
          fontStyles: {
            textAlign: TextAlign.left,
          },
        },
      ],
      [],
      [],
      [],
      [],
      [],
    ]),
    ...{ tags: [LAYOUT_TAG_WALLPAPERS] },
  },
  {
    name: 'grid-empty',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 0,
      rows: 0,
      shiftSteps: 0,
      spacing: numberToOffset(0),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [],
  },
  {
    name: 'grid-vertical-0',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(5),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [{ type: 'image', width: 2, height: 6, unlocked: true }],
      [],
      [],
      [],
      [],
      [],
      [
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.MONTH,
          vAlignment: 'middle',
        },
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.YEAR,
          vAlignment: 'middle',
        },
      ],
      [
        {
          // TODO we need to get the grid design dynamycally from the store
          type: 'grid',
          designName: designs.defaultGridDesign.name,
          width: 2,
          height: 6,
        },
      ],
      [],
      [],
      [],
      [],
      [],
    ],
  },
  {
    name: 'grid-vertical-1',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 13,
      shiftSteps: 1,
      spacing: numberToOffset(5),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        { type: 'image', width: 1, height: 6, unlocked: true },
        { type: 'image', width: 1, height: 6, unlocked: true },
      ],
      [],
      [],
      [],
      [],
      [],
      [
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.MONTH,
          vAlignment: 'middle',
        },
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.YEAR,
          vAlignment: 'middle',
        },
      ],
      [
        {
          // TODO we need to get the grid design dynamycally from the store
          type: 'grid',
          designName: designs.defaultGridDesign.name,
          width: 2,
          height: 6,
        },
      ],
      [],
      [],
      [],
      [],
      [],
    ],
  },
  {
    name: 'grid-vertical-2',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 14,
      shiftSteps: 1,
      spacing: numberToOffset(5),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: true,
    },
    frameRows: [
      [{ type: 'image', width: 2, height: 7, unlocked: true }],
      [],
      [],
      [],
      [],
      [],
      [],
      [
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.MONTH,
          vAlignment: 'middle',
          padding: {
            left: 10,
            top: 0,
            right: 0,
            bottom: 0,
          },
        },
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.YEAR,
          vAlignment: 'middle',
          padding: {
            left: 0,
            top: 0,
            right: 10,
            bottom: 0,
          },
        },
      ],
      [
        {
          // TODO we need to get the grid design dynamycally from the store
          type: 'grid',
          designName: designs.defaultGridDesign.name,
          width: 2,
          height: 6,
          padding: {
            left: 10,
            top: 0,
            right: 10,
            bottom: 10,
          },
        },
      ],
      [],
      [],
      [],
      [],
      [],
    ],
  },
  {
    name: 'grid-vertical-3',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 14,
      shiftSteps: 1,
      spacing: numberToOffset(5),
      padding: numberToPadding(0),
      offset: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: true,
    },
    frameRows: [
      [
        { type: 'image', width: 1, height: 7, unlocked: true },
        { type: 'image', width: 1, height: 7, unlocked: true },
      ],
      [],
      [],
      [],
      [],
      [],
      [],
      [
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.MONTH,
          vAlignment: 'middle',
          padding: {
            left: 10,
            top: 0,
            right: 0,
            bottom: 0,
          },
        },
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.YEAR,
          vAlignment: 'middle',
          padding: {
            left: 0,
            top: 0,
            right: 10,
            bottom: 0,
          },
        },
      ],
      [
        {
          // TODO we need to get the grid design dynamycally from the store
          type: 'grid',
          designName: designs.defaultGridDesign.name,
          width: 2,
          height: 6,
          padding: {
            left: 10,
            top: 0,
            right: 10,
            bottom: 10,
          },
        },
      ],
      [],
      [],
      [],
      [],
      [],
    ],
  },
  {
    name: 'grid-vertical-4',
    tags: [LAYOUT_TAG_GRID],
    params: {
      cols: 2,
      rows: 14,
      shiftSteps: 1,
      spacing: numberToOffset(10),
      padding: numberToPadding(EXTERNAL_PADDING),
      offset: {
        top: EXTERNAL_PADDING / 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      useBleed: false,
    },
    frameRows: [
      [
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.MONTH,
          vAlignment: 'middle',
        },
        {
          type: 'generated-text',
          width: 1,
          height: 1,
          textType: LayoutGeneratedTextType.YEAR,
          vAlignment: 'middle',
        },
      ],
      [
        {
          // TODO we need to get the grid design dynamycally from the store,
          type: 'grid',
          designName: designs.defaultGridDesign.name,
          width: 2,
          height: 13,
        },
      ],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
  },
  defineLayoutSchema(
    'mockup',
    1,
    1,
    10,
    0,
    0,
    0,
    [
      [
        {
          width: 1,
          height: 1,
          type: 'image',
          role: MockupRole.Background,
        },
        {
          type: 'image',
          role: MockupRole.Placeholder,
          freePlacement: 'center',
          shadow: {
            type: 'drop-shadow',
            blur: 0.2,
            color: '#999999',
            offsetX: -1,
            offsetY: 1,
            scale: 0,
          },
        },
      ],
    ],
    true,
    [LAYOUT_TAG_MOCKUP],
  ),
  defineLayoutSchema(
    'mockup-without-shadow',
    1,
    1,
    10,
    0,
    0,
    0,
    [
      [
        {
          width: 1,
          height: 1,
          type: 'image',
          role: MockupRole.Background,
        },
        {
          type: 'image',
          role: MockupRole.Placeholder,
          freePlacement: 'center',
        },
      ],
    ],
    true,
    [LAYOUT_TAG_MOCKUP],
  ),
  defineLayoutSchema(
    'mockup-with-foreground',
    1,
    1,
    10,
    0,
    0,
    0,
    [
      [
        {
          width: 1,
          height: 1,
          type: 'image',
          role: MockupRole.Background,
        },
        {
          type: 'image',
          role: MockupRole.Placeholder,
          freePlacement: 'center',
          shadow: {
            type: 'drop-shadow',
            blur: 0.2,
            color: '#999999',
            offsetX: -1,
            offsetY: 1,
            scale: 0,
          },
        },
        {
          width: 1,
          height: 1,
          type: 'image',
          role: MockupRole.Foreground,
          freePlacement: 'center',
          static: true,
        },
      ],
    ],
    true,
    [LAYOUT_TAG_MOCKUP],
  ),
];

export default schemas;
